<template>
  <div>
    <div class="page">
      <div v-if="getModuleConfig('worker_staff_page.form_title')" class="page__title">
        {{ getModuleConfig("worker_staff_page.form_title") }}
      </div>
      <div v-if="getModuleConfig('worker_staff_page.form_desc')" class="page__desc">
        {{ getModuleConfig("worker_staff_page.form_desc") }}
      </div>
      <b-card class="mt-3 mb-3" img-top v-if="workerInfo">
        <div v-for="info in showInfos" :key="info.matched_key" class="field">
          <template v-if="info.display && getWorkerInfo(info.matched_key)">
            <div class="field__label">
              {{ info.title }}
            </div>
            <div class="field__desc">{{ getWorkerInfo(info.matched_key) }}</div>
          </template>
        </div>
      </b-card>
      <div v-if="getModuleConfig('worker_staff_page.content')" v-html="getModuleConfig('worker_staff_page.content')">
      </div>
    </div>

    <div class="page__button s-space-y-4">
      <MemberButton :button-text="getModuleConfig('worker_staff_page.button_text')"
        :button-url="getModuleConfig('worker_staff_page.button_url')"
        :type="getModuleConfig('worker_staff_page.button_action')">
      </MemberButton>
    </div>
  </div>
</template>

<script>
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import workerMixin from "@/mixins/liff/worker";
import workerApi from "@/apis/liff/v2/worker";

export default {
  mixins: [workerMixin],
  components: {
    MemberButton,
  },

  data() {
    return {
      showLoading: false,
      showInfos: {},
      workerInfo: {
        // id: "w3d9npke2pn6",
        // name: "西蒙",
        // employee_code: "cc614",
        // avatar_url: "https://profile.line-scdn.net/0hcXlELn5APHdBPCnVq55CCDFsPx1iTWVlZQgjQyZpMkIpDX4laF4hEyZvMUJ6Diwla116FyA4axJNL0sRX2rAQ0YMYkB4Cn4mb1t1lw",
        // service_unit: "02 位名稱",
        // email: "xing1615@gmail.com",
        // mobile_phone: "0917075299",
      },
    };
  },

  async mounted() {
    await this.init();
  },

  computed: {
  },

  methods: {
    async init() {
      if (this.$route.query) {
        const { data } = await workerApi.getInfo(this.$route.query);
        this.showInfos = this.getModuleConfig("worker_staff_page.info")
          .sort(function (a, b) {
            if (a.order > b.order) {
              return 1;
            }
            if (a.order < b.order) {
              return -1;
            }
            // a order must be equal to b
            return 0;
          })
          .filter((info) => info.type !== "img");
        this.workerInfo = data.data[0];
      }
    },
    getWorkerInfo(code) {
      return this.workerInfo[code] || null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/shared/components/_fields.scss';
</style>
